// React
import React from 'react'

// Next
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'

// Components
import { Button } from '@/components/ui/button'

// Utils
import { getLandingStaticProps } from '@/utils/ui/landing/get-static-props'

// External
import { HomeIcon, LifeBuoyIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation('routes/404')

  return (
    <>
      <Head>
        <title>{t('meta.title')}</title>
        <meta property='og:url' content='https://supermeme.ai' />
        <link rel='canonical' href='https://supermeme.ai' />
      </Head>
      <div className='flex h-[70vh] flex-col items-center justify-center'>
        <h2 className='mb-8 text-center text-2xl font-bold'>{t('heading')}</h2>
        <div className='w-[480px]'>
          <Image
            src={'/static/404.gif'}
            alt={t('image.alt')}
            width={480}
            height={270}
          />
        </div>
        <div className='mt-10 flex items-center justify-center space-x-6'>
          <Link href='/' legacyBehavior>
            <a className='primary-button-solid'>
              <HomeIcon className='h-5 w-5' />
              <span>{t('buttons.home')}</span>
            </a>
          </Link>
          <Button variant='default' asChild>
            <Link href='/contact'>
              <LifeBuoyIcon className='mr-2 h-5 w-5' />
              <span>{t('buttons.support')}</span>
            </Link>
          </Button>
        </div>
      </div>
    </>
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) =>
  getLandingStaticProps({
    locale,
    namespaces: ['common', 'routes/404'],
  })

export default NotFoundPage
